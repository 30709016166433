import axios from "axios";

export function checkLogin() {
  return false;
}

let api = "https://internal-api.tracelight.io";

const devHosts = [
  "localhost:8080",
  "pilot.tracelight.io",
  "pilot-stable.tracelight.io",
];

if (devHosts.includes(window.location.host)) {
  api = "https://internal-api-pilot.tracelight.io";
}

export function initLogin() {
  const path = `/auth/init?redirect_uri=${window.location.origin}/login`;
  const url = `${api}${path}`;

  axios
    .get(url)
    .then((response) => {
      let state, authUrl, redirect_uri, client_id, response_type, scope;
      for (const [key, val] of Object.entries(response.data)) {
        if (key == "data") {
          state = val.params.state;
          client_id = val.params.client_id;
          redirect_uri = val.params.redirect_uri;
          authUrl = val.url;
          response_type = val.params.response_type;
          scope = val.params.scope;
        }
      }
      window.location = `${authUrl}?scope=${scope}&redirect_uri=${redirect_uri}&response_type=${response_type}&client_id=${client_id}&state=${state}`;
    })
    .catch(function(error) {
      console.error(error);
      return;
    });
}

export function completeLogin(payload) {
  const path = "/auth/complete";
  const url = `${api}${path}`;
  payload.redirect_uri = window.location.origin + "/login";
  return axios
    .post(url, payload)
    .then((response) => {
      console.log(response);
      const oneMin = 60 * 1000;
      const loginTime = Date.now();
      const expire = response.data.data.authentication.expires_in * 1000;
      const expireTime = loginTime + expire - oneMin;
      window.localStorage.setItem(
        "access_token",
        response.data.data.authentication.access_token
      );
      window.localStorage.setItem("loginTime", loginTime);
      window.localStorage.setItem("expireTime", expireTime);
    })
    .catch(function(error) {
      console.error(error);
      return;
    });
}
