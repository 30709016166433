<template>
  <div>
    <div class="mb-4">
      <img
        src="https://d3oia8etllorh5.cloudfront.net/us-east-1_dM0EI6uHe/ALL/20211208193832/assets/images/image.jpg"
      />
    </div>
    <v-alert icon="mdi-login" prominent text type="info">
      <v-row align="center" no-gutters>
        <v-col class="grow">
          <h2 class="font-weight-light">Logging in...</h2>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="shrink">
          <v-progress-circular
            class="ml-n12"
            :size="30"
            :width="5"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
import { handleLogin, refreshCreds } from "@/clients/auth";
import { recordLogin } from "@/clients/tracelight";

export default {
  name: "LoggedIn",
  created: function () {
    handleLogin().then(() => {
      refreshCreds().then(() => {
        recordLogin({}).then((r) => {
          console.log("Logged in ", r);
          let nextPath = "/";
          if (window.localStorage.nextPath !== undefined) {
            nextPath = window.localStorage.nextPath;
          }
          this.$router.push({ path: nextPath });
        });
      });
    });
  },
};
</script>
